'format es6';
'use strict';

import $ from 'jquery';
import { docReady } from './utils/docReady';
import { lockBody, unlockBody } from './utils/lockBody';

const $window = $(window);
const OPENED_CLASS = 'opened';

let body;
let mode = '';
let mainMenu;
let menuBtn;
let overlay;

let mobileMenuOpened = false;

function getMode() {
	let m = 'desktop';
	if ($window.width() < 1200) {
		m = 'mobile';
	}

	if (!body.hasClass(m)) {
		body.removeClass('desktop mobile').addClass(m);
	}

	return m;
}

function addMobileEventListeners() {
	overlay = $('.menu-overlay');
	menuBtn = $('.btn-menu').off('click.menu').on('click.menu', () => {
		mainMenu.toggleClass(OPENED_CLASS);
		
		setTimeout(() => {
			mobileMenuOpened = !mobileMenuOpened;
		}, 1);

		if (mainMenu.hasClass(OPENED_CLASS)) {
			lockBody();
		} else {
			unlockBody();
		}
	});

	overlay.on('click', () => {
		mainMenu.removeClass(OPENED_CLASS);
		unlockBody();
	});
}

function removeMobileEventListeners() {
	$('.more', menuBtn).off('click.menu');
	$('.more-menu .menu-item-2.has-submenu > a').off('click.menu');
}

function cleanup() {
	if (mode === 'mobile') {
		addMobileEventListeners();
	} else {
		removeMobileEventListeners();
	}
}

function resizeHandler() {
	const newMode = getMode();
	if (mode !== newMode) {
		mode = newMode;
		cleanup(mode);
	}
}

function Menu() {
	this.init = () => {
		body = $('body');
		mainMenu = $('.main-menu');

		$window.on('resize', resizeHandler);
		resizeHandler();
	};
}

docReady.then(() => {
	const m = new Menu();
	m.init();
});

'format es6';
'use strict';

import $ from 'jquery';
import ns from 'ns';
import gsap, { TweenMax, TimelineMax } from 'gsap';
import Promise from 'Promise';

import Menu from './Menu';
import Masthead from './Masthead';
import Carousel from './Carousel';

$(document).ready(() => {
	const $w = $(window);

	const alert = $('.alert');

	if (alert.length !== 0) {
		const alertBtn = $('.icon-ctn', alert);
		const alertCtn = $('.alert-ctn', alert);

		alertBtn.on('click', () => {
			if ($w.width() <= 767) {
				alertBtn.toggleClass('active');
				alertCtn.slideToggle();
			}
		});
	}

	$('a[href*=".pdf"]').attr('target', '_blank');

	const backtop = $('.back-top');

	backtop.on('click', () => {
		const scroll = {
			y: $w.scrollTop(),
		};

		TweenMax.to(scroll, 0.6, {
			y: 0,
			onUpdate: () => $w.scrollTop(scroll.y),
			ease: gsap.Cubic.easeOut,
		});
	});

	const ancresCtn = $('[data-anchors]');
	const anchors = $('.container.content article a[id]');
	if (ancresCtn.length > 0 && anchors.length > 0) {
		ancresCtn.append($('<h2>Liens rapides</h2>'));
		ancresCtn.append($('<ul />'));
		anchors.each((i, elem) => {
			const anchor = $(elem);
			const link = $(`<li><a href="#${anchor.attr('id')}">${anchor.parent().text()}</a></li>`);
			ancresCtn.find('ul').append(link);
		});
	}

	ancresCtn.find('h2').on('click', () => {
		ancresCtn.toggleClass('active');
	});

	const readMoreButtons = $('aside .read-more').wrap('<div />');


	Carousel.init();
});

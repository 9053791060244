'format es6';
'use strict';

import $ from 'jquery';
import { TweenMax } from 'gsap';
import { docReady } from './utils/docReady';

function cycle(imgs) {
	let next = imgs.filter('.active').next();
	if (next.length === 0) next = imgs.eq(0);

	imgs.removeClass('active');
	next.addClass('active');

	let delay = parseFloat(next.attr('data-delay'));
	if (!delay) delay = 5;

	TweenMax.delayedCall(delay, cycle, [imgs]);
}

docReady.then(() => {
	const masthead = $('.masthead');

	if (masthead.length === 0) return;

	const imgs = masthead.find('.bg > div');

	if (imgs.length > 1) {
		const active = imgs.filter('.active');
		if (active.length === 0) {
			cycle(imgs);
		} else {
			TweenMax.delayedCall(5, cycle, [imgs]);
		}
	}
});

import $ from 'jquery';
import 'slick-carousel';

function init() {
	const carousels = document.querySelectorAll('[data-carousel]');

	Array.from(carousels).forEach((el) => {
		$(el).slick({
			rows: 0,
			arrows: true,
		});
	});
}

export default {
	init,
};
